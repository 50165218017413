.antd-container {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--colorGrey2);
}

@transition-curve: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

// Sadly you can't set the 'base container' for antd to style, so we manually replicate from
// https://github.com/ant-design/ant-design/blob/7d0193e9458dae0355599b2d4bf93464993429f8/components/style/core/global.less#L57-L66
#sidebar-root {
  margin: 0; // 1
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #FFFFFF; // 2
  font-feature-settings: 'tnum';
  text-shadow: none;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

button {
  box-shadow: none;
}

button.ant-btn-primary {
  box-shadow: none;
}

.ant-layout {
  background-color: var(--colorBgBase);
}

.ant-card {
  background-color: var(--cardColor);
  color: var(--colorTextBase);
}

.ant-breadcrumb-separator {
  color: var(--colorTextBase);
}

.ant-modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.ant-modal-content {
  border-radius: 12px;
}

.ant-legacy-form-vertical .ant-legacy-form-item-label {
  padding: 0 !important;
}

.ant-legacy-form-item-label label {
  padding: 0;
}

.ant-legacy-form .ant-legacy-form-item, .ant-form-item {
  margin-bottom: 16px;
}

.ant-modal-title {
  font-size: 22px;
}

.onbModal .ant-steps-item-icon {
  margin-top: 5px;
}
.onbModal .ant-steps-item-tail {
  margin-top: 5px;
}

.onbModal .ant-steps-item-content {
  padding-left: 16px;
}

.ant-modal-close {
  background-color: var(--colorBgBase);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: all 0.25s ease-in;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    box-shadow: 0 10px 10px 0 rgba(0, 57, 128, 0.2);
  }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  color: var(--colorTextBase);
}

.ant-dropdown .ant-dropdown-menu {
  background-color: var(--cardColor);
}

.ant-modal-close-x {
  height: inherit;
  width: inherit;
  color: var(--colorSeverityCritical);
}

.ant-modal-close-icon {
  color: var(--colorSeverityCritical);
}

.ant-dropdown, .ant-dropdown-menu {
  color: var(--colorTextBase);
  background-color: var(--cardColor);
}

.ant-dropdown-menu-item {
  color: var(--colorTextBase);
}


h1 {
  font-size: 2rem;
  line-height: 1.1765em;
  margin-top: 0.5882em;
  color: var(--colorTextBase);
}

h2 {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 0.5em 0 1.1em 0;
  color: var(--colorTextBase);
}

h3 {
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 0.9em 0 0.5em 0;
}

h2 + .ant-tabs {
  margin-top: -1.25em;
}

h1 {
  font-size: 2rem;
  line-height: 1.1765em;
  margin-top: 0.5882em;
  color: var(--colorTextBase);
}

.intro {
  font-size: 1.5rem;
  color: var(--colorTextBase);
  line-height: 1.5em;
  margin: 1.6em auto;
  max-width: 820px;
}

.ant-btn,
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  outline: 0;
  box-shadow: none;
  font-weight: 800;
  line-height: 22px;
}

//Buttons

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #1A49B0;
  color: #fff;
}

.ant-btn-primary {
  transition: @transition-curve;
  color: #ffffff;
  background-color: #3f71e0;
  border-radius: 20px;
  padding: 4px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  line-height: 22px;

    &:hover {
      background-color: #1A49B0;
      color: #fff;
      text-decoration: none;
    }

    &:active, &:focus {
      background-color: #133683;
      color: #fff;
    }

    &:disabled {
      background-color: #97B3EE;
      color: #fff;
  }
}

.ant-btn-default {
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #3F71E0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  line-height: 22px;

  &:hover, &:focus {
    border-color: #1A49B0;
    background-color: #1A49B0;
    color: white !important;
  }

  &:active {
    border-color: #133683;
    background-color: #133683;
    color: white !important;
  }

  &:disabled {
    border: 1px solid #97B3EE;
    background-color: #97B3EE;
    color: white;
  }
}

.ant-btn-text {
  color: #3F71E0;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-decoration: none;

  &:hover {
    color: #1A49B0 !important;
    background-color: #0000000F !important;
  }

  &:active, &:focus {
    color: #133683 !important;
    background-color: #0000000F !important;
  }

  &:disabled {
    color: #97B3EE;
  
  }
}

.ant-btn-link {
  display: inline-flex;
  align-items: center;
  color: var(--navigationLink);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-decoration: none;
  
  &:hover {
    color: #1A49B0;
    text-decoration: none;
  }

  &:active, &:focus {
    color: #133683;
    text-decoration: none;
  }

  &:disabled {
    color: #97B3EE;
  }
}


.ant-btn::after {
  display: none;
}

.ant-btn:disabled {
  opacity: 0.5;
}

.ant-legacy-form-explain {
  padding-top: 10px;
}

.ant-input::placeholder {
  font-size: 14px;
}
.ant-card-cover {
  position: relative;
}

.ant-legacy-form-vertical .ant-legacy-form-explain {
  margin-bottom: 1em;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-width: 1px;
}

.ant-table-thead tr th {
  background-color: var(--colorGrey1) !important;
  color: white;
  padding: 16px;
}

.ant-spin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

th.ant-table-column-has-sorters:hover {
  background-color: var(--colorGrey1) !important;
  color: white !important;
}

.ant-table-row:hover td {
  background-color: #f1f1f1 !important;
  cursor: pointer;
}

.ant-table {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 0.3em;
}

.ant-table-column-sort th,
.ant-table-column-sort th:hover {
  background: var(--colorGrey1) !important;
}

.ant-table-column-sort .ant-table-column-title {
  color: var(--colorPrimary);
}

.ant-table {
  border-radius: 5px;
}

//getting rid of antd text-shadow on tabs 
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
}

.ant-popconfirm-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}